<template>
  <div class="page-main-box">
    <Button
      v-if="$store.getters.roles.includes('AccountNumberButton')"
      class="px-24 ml-24 mt-24 fz-12"
      type="primary"
      @click="handleAmend('add')"
      >新建员工</Button
    >
    <!-- 有数据 -->
    <div class="pt-24 pl-16 pr-16">
      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          id="service-order-table"
          :dataSource="tableDataSource"
          height="auto"
        >
          <p-t-column type="seq" width="60" title="序号" />
          <p-t-column field="username" min-width="180" title="账号" />
          <p-t-column field="name" min-width="149" title="姓名" />
          <p-t-column field="roleNames" min-width="311" title="角色">
            <template v-slot="{ row }">
              <span> {{ row.roleNames.join() }}</span>
            </template>
          </p-t-column>
          <p-t-column field="create_time" min-width="176" title="首次登陆时间">
            <template v-slot="{ row }">
              <span>{{
                formatToDateTime(row.create_time, "yyyy-MM-dd hh:mm:ss")
              }}</span>
            </template>
          </p-t-column>
          <p-t-column width="110" title="操作" fixed="right">
            <template v-slot="{ row }">
              <Button
                type="link"
                @click="handleAmend('amend', row)"
                :disabled="
                  !$store.getters.roles.includes('AccountNumberButton')
                "
                v-if="row.roles[0].code !== 'SUPER'"
              >
                修改
              </Button>
              <Button
                type="link"
                @click="handleDelete(row)"
                :disabled="
                  !$store.getters.roles.includes('AccountNumberButton')
                "
                v-if="row.roles[0].code !== 'SUPER'"
              >
                删除
              </Button>
              <Button
                type="link"
                @click="transferModal = true"
                v-if="
                  row.roles[0].code === 'SUPER' &&
                    $store.getters.roles.includes('SUPER')
                "
              >
                转让超管
              </Button>
            </template>
          </p-t-column>
        </p-table>
      </div>
    </div>

    <!-- 转让超管弹窗 -->
    <TransferAuthority
      v-if="transferModal"
      :visible="transferModal"
      @cancel="transferModal = false"
    >
    </TransferAuthority>

    <!-- 新增/修改角色弹窗 -->
    <AmendAuthority
      v-if="amendModal"
      :visible="amendModal"
      :dataInfo="dataInfo"
      :type="actionType"
      @getData="refresh"
      @getAccountInfo="handleGetAccountInfo"
      @cancel="amendModal = false"
    >
    </AmendAuthority>

    <!-- 删除确认框 -->
    <xModal
      :w="288"
      :visible="deleteModal"
      :closable="false"
      @handleOk="handleOk(dataInfo)"
      @handleCancel="deleteModal = false"
    >
      <template #content>
        <p class="text-center">确定要删除该员工账号吗？</p>
      </template>
    </xModal>

    <!-- 查看账号密码 -->
    <xModal
      :w="330"
      :visible="infoModal"
      :closable="false"
      :footer="null"
      @handleOk="handleCopy"
      @handleCancel="infoModal = false"
    >
      <template #content>
        <div class="d-flex align-center flex-column">
          <p class="fz-16 fw-600 d-flex align-center height-16">
            <Icon
              class="mr-8"
              name="iconchenggongtoast"
              height="16"
              width="16"
            ></Icon>
            <span style="line-height: 16px;">添加成功</span>
          </p>
          <div class="info-box">
            <p>
              登录账号：<span>{{ accountInfo.username }}</span>
            </p>
            <p>
              初始密码：<span>{{ accountInfo.password }}</span>
            </p>
          </div>
        </div>

        <div class="pt-24">
          <a-button @click="infoModal = false">取消</a-button>
          <a-button
            id="copy"
            class="ml-16"
            type="primary"
            :data-clipboard-text="
              '登录账号：' +
                accountInfo.username +
                '初始密码：' +
                accountInfo.password
            "
            @click="handleCopy"
            >复制账号密码
          </a-button>
        </div>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { Button, message } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { useUserPageApi, useUserRevokeRoleApi } from "@/apis/system";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import useTableHeight from "@/hooks/useTableHeight";
import TransferAuthority from "./components/TransferAuthority/index";
import AmendAuthority from "./components/AmendAuthority/index";
import xModal from "@/components/xModal";
import Clipboard from "clipboard";

export default {
  name: "service-contract",
  components: { Button, TransferAuthority, AmendAuthority, xModal },
  setup() {
    const userPageApi = useUserPageApi();
    const userRevokeRoleApi = useUserRevokeRoleApi();

    // table高度
    const tableHeight = ref();

    const transferModal = ref(false);
    const amendModal = ref(false);
    const deleteModal = ref(false);
    const infoModal = ref(false);
    const dataInfo = ref({});
    const actionType = ref("");

    const accountInfo = ref({});

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const refresh = Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(userPageApi(data));

        res.list.map(i => {
          i.roleNames = [];
          i.roleIds = [];
          i.roles.map(j => {
            i.roleNames.push(j.name);
            i.roleIds.push(j.id);
          });
        });
        return useTableDataRecords(res);
      }
    });

    // 修改权限
    const handleAmend = (type, row) => {
      dataInfo.value = row;
      amendModal.value = true;
      actionType.value = type;
    };

    // 删除账号
    const handleDelete = row => {
      dataInfo.value = row;
      deleteModal.value = true;
    };

    // 确认删除
    const handleOk = async row => {
      const role_ids = row.roles.map(item => item.id);
      const [, res] = await $await(
        userRevokeRoleApi({ user_id: row.id, role_ids: role_ids })
      );
      if (res) {
        deleteModal.value = false;
        refresh();
        message.success("删除成功！");
      }
    };
    // 查看账号密码
    const handleGetAccountInfo = info => {
      infoModal.value = true;
      accountInfo.value = info;
    };

    // 复制账号密码
    const handleCopy = () => {
      var clipboard = new Clipboard("#copy");

      clipboard.on("success", function(e) {
        message.success("复制成功！");
        e.clearSelection();
      });

      clipboard.on("error", function(e) {
        console.error("Action:", e.action);
        console.error("Trigger:", e.trigger);
      });
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      transferModal,
      amendModal,
      deleteModal,
      infoModal,
      accountInfo,
      actionType,
      tableDataSource,
      formatToDateTime,
      dataInfo,
      handleAmend,
      handleDelete,
      handleOk,
      handleGetAccountInfo,
      handleCopy,
      refresh
    };
  }
};
</script>

<style lang="less" scoped>
.info-box {
  width: 224px;
  height: 96px;
  background: #f8f9fb;
  border-radius: 4px;
  padding: 0 16px;
  margin-top: 24px;
  p {
    color: #808080;
    line-height: 48px;
    span {
      color: #4d4d4d;
      float: right;
    }
  }
}
</style>
