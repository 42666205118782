// import * as rule from "@wlhy-web-lib/rule/esm";

export const getInvoiceRules = () => {
  const rules = {
    mobile: [
      {
        required: true,
        trigger: "blur",
        message: "员工账号不能为空"
      },
      {
        trigger: "blur",
        pattern: /^[1][0-9]{10}$/,
        message: "请输入有效手机号"
      }
    ],
    name: [
      {
        required: true,
        trigger: "blur",
        message: "员工姓名不能为空"
      }
    ],
    role_ids: [
      {
        required: true,
        type: "array",
        trigger: "blur",
        message: "本账号角色不能为空"
      }
    ]
  };
  return rules;
};
