import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/company");

// 系统设置/账号管理/获取用户列表（分页）
export const useUserPageApi = basicApi({
  url: "/rbac/user/page",
  withCredentials: true
});

// 系统设置/账号管理/获取用户列表（不分页）
export const useUserListApi = basicApi({
  url: "/rbac/user/list",
  withCredentials: true
});

// 系统设置/账号管理/角色列表（不分页）
export const useRoleListApi = basicApi({
  url: "/rbac/role/list",
  withCredentials: true
});

// 系统设置/账号管理/转让超级管理员角色
export const useTransferSuperRoleApi = basicApi({
  url: "/rbac/role/transfer_super_role",
  withCredentials: true
});

// 系统设置/账号管理/分配角色
export const useGrantRoleApi = basicApi({
  url: "/rbac/user/grant_role",
  withCredentials: true
});

// 系统设置/角色权限管理/新增角色
export const useAddRoleApi = basicApi({
  url: "/rbac/role/add",
  withCredentials: true
});

// 系统设置/角色权限管理/角色权限详情
export const useRoleDetailApi = basicApi({
  url: "/rbac/role/detail",
  withCredentials: true
});

// 系统设置/角色权限管理/菜单树
export const useMenuTreeApi = basicApi({
  url: "/rbac/menu/menu_tree",
  withCredentials: true
});

// 系统设置/角色权限管理/更新角色的权限信息
export const useUpdateMenuApi = basicApi({
  url: "/rbac/role/update_menu",
  withCredentials: true
});

// 系统设置/角色权限管理/删除角色
export const useRoleDeleteApi = basicApi({
  url: "/rbac/role/delete",
  withCredentials: true
});

// 服务商列表
export const useProviderApi = basicApi({
  url: "/service_config/provider_list",
  withCredentials: true
});

// 服务项配置详情
export const useConfigureDetailApi = basicApi({
  url: "/service_config/config_detail",
  withCredentials: true
});

// 服务项配置项
export const useConfigureApi = basicApi({
  url: "/service_config/config",
  withCredentials: true
});

// 系统设置/角色权限管理/删除账号
export const useUserRevokeRoleApi = basicApi({
  url: "/rbac/user/revoke_role",
  withCredentials: true
});

// 系统设置/角色权限管理/删除账号
export const useUserAddApi = basicApi({
  url: "/rbac/user/add",
  withCredentials: true
});
